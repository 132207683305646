import React, { useState, useEffect } from "react";
import { fetchToken, registerLeagueLeadSlip } from "../../../utils/LeagueUtils";
function Leagueleadslip() {
  const [LeagueForm, setLeagueForm] = useState({
    To: "",
    LeagueLeadType: "",
    LeagueLeadStatus: "",
    LeagueLeadStatus2: "",
    Telephone: "",
    Email: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLeagueForm((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const [insideButtonSelected, setInsideButtonSelected] = useState(true);
  const [outsideButtonSelected, setOutsideButtonSelected] = useState(false);

  const handleInsideButtonClick = () => {
    setInsideButtonSelected(true);
    setOutsideButtonSelected(false);
    setLeagueForm((prevFormData) => ({
      ...prevFormData,
      LeagueLeadType: "Inside",
    }));
  };

  const handleOutsideButtonClick = () => {
    setInsideButtonSelected(false);
    setOutsideButtonSelected(true);
    setLeagueForm((prevFormData) => ({
      ...prevFormData,
      LeagueLeadType: "Outside",
    }));
  };

  // Fetch token from the fetch function
  const fetchData = async () => {
    try {
      const accessToken = await fetchToken(username, password);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const accessToken = await fetchToken(username, password);
      const LeagueData = {
        // passing league as static will change after updating the UI
        ListType: "League",
        To: LeagueForm.To,
        LeagueLeadType: LeagueForm.LeagueLeadType,
        LeagueLeadStatus: LeagueForm.LeagueLeadStatus,
        LeagueLeadStatus2: LeagueForm.LeagueLeadStatus2,
        LeagueLead: "LeagueLeadTest",
        Telephone: LeagueForm.Telephone,
        Email: LeagueForm.Email,
        Address: "League Address",
        CompanyId: "1",
      };
  
      const registerLeagueLead = await registerLeagueLeadSlip(
        accessToken,
        LeagueData
      );
  
      console.log("Registered League Lead Slip:", registerLeagueLead);
  
    } catch (error) {
      console.error("Error registering league:", error.message);
      if (error.response && error.response.status === 200) {
        console.log("API Message:", error.response.data.message);
      }
    }
  
    console.log(LeagueForm); // The form data is available in the LeagueForm state
  };
  
  // API validation
  const username = "dtechraabit";
  const password = "admin123";

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <form  onSubmit={handleSubmit}  className="xs:mt-8 w-[50%] mx-auto lg:mt-[3rem]">
        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field1"
          >
            To
          </label>
          <input
            className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="To"
            value={LeagueForm.To}
            onChange={handleChange}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field2"
          >
            League Lead Type
          </label>
          <div className="flex flex-row justify-center gap-[4px]">
            <input
              type="button"
              value="Inside"
              className={`p-2 m-2 rounded-md w-[9rem] ${
                insideButtonSelected
                  ? "bg-green-700 text-white"
                  : "bg-white border border-green-700 text-black"
              }`}
              onClick={handleInsideButtonClick}
            />

            <input
              type="button"
              value="outside"
              className={`w-[9rem] p-2 m-2 rounded-md ${
                outsideButtonSelected
                  ? "bg-green-700 text-white "
                  : "bg-white border border-green-700 text-black"
              }`}
              onClick={handleOutsideButtonClick}
            />
          </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field3"
          >
            League Lead Status
          </label>
          <input
            className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="LeagueLeadStatus"
            value={LeagueForm.LeagueLeadStatus}
            onChange={handleChange}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field4"
          >
            League Lead status 2
          </label>
          <input
            className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="LeagueLeadStatus2"
            value={LeagueForm.LeagueLeadStatus2}
            onChange={handleChange}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field5"
          >
            Telephone
          </label>
          <input
            className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="Telephone"
            value={LeagueForm.Telephone}
            onChange={handleChange}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field5"
          >
            Email
          </label>
          <input
            className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="Email"
            value={LeagueForm.Email}
            onChange={handleChange}
          />
        </div>

        <div className="lg:mb-[4rem] flex flex-col gap-3 justify-center items-center mt-[3rem] mb-4">
          <button
            className="btn-league w-[17rem] p-[4px] rounded-md mont-serif fade-in-left"
            type="submit"
          >
            Confirm
          </button>
        </div>
      </form>
    </>
  );
}

export default Leagueleadslip;
