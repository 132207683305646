import React from "react";
import AdminSidebar from "../AdminSidebar";
import AdminSearchbar from "../AdminSearchbar";
import AdminFooter from "../Adminfooter";
import PendingSlider from "./PendingSlider";
import ApprovedSlider from "./ApprovedSlider";
import LeagueMeeting from "./LeagueMeeting";
import EventHistoryTable from "./EventHistoryTable";
import Adminnav from "../Adminnav";
function AdminEventmain() {
  return (
    <>
      <div className="lg:block xs:hidden">
        <AdminSidebar />
      </div>
      <div className="xs:block lg:hidden">
        <Adminnav />
      </div>
      <main className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <div className="hidden lg:block">
          <header className=" header bg-white shadow py-4 px-4 ">
            <AdminSearchbar />
          </header>
        </div>
        <div className="main-content flex flex-col flex-grow p-4">
          {/* <AdminCount/> */}

          <PendingSlider />
          <ApprovedSlider />
          <LeagueMeeting />
          <EventHistoryTable />
        </div>
      </main>

      <AdminFooter />
    </>
  );
}

export default AdminEventmain;
