import axios from "axios";

const BASE_URL = "http://78.46.90.147:80/api/v1/data/";

const API_USERNAME = "dtechraabit"; 
const API_PASSWORD = "admin123"; 
// access tken to authorize other apis 
export const fetchToken = async () => {
  try {
    const response = await axios.post(`${BASE_URL}gettoken`, {
      username: API_USERNAME,
      password: API_PASSWORD,
    });

    if (response.status === 200) {
      const accessToken = response.data.data.access_token;
      localStorage.setItem("accessToken", accessToken); // Save token to local storage
      console.log("Access Token:", accessToken); 
      return accessToken;
    } else {
      throw new Error("Failed to fetch token");
    }
  } catch (error) {
    throw new Error("Failed to fetch token");
  }
};

// function to popuate option in select area regestration form 
export const fetchAllLeagues = async (accessToken) => {
  try {
    const response = await axios.get(`${BASE_URL}getallleagues`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      const leagues = response.data.data;
      console.log("Leagues data:", leagues); // Add this line to log the data
      return leagues;
    } else {
      throw new Error("Failed to fetch leagues");
    }
  } catch (error) {
    console.error("Error fetching leagues:", error.message);
    throw new Error("Failed to fetch leagues");
  }
};

// function to popuate option in select area regestration form 
export const fetchCompanies = async (accessToken) => {
  try {
    const response = await axios.get(`${BASE_URL}getallcompanies`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error("Failed to fetch companies");
    }
  } catch (error) {
    throw new Error("Failed to fetch companies");
  }
};
// registerUser 
export const registerUser = async (accessToken, userData) => {
  try {
    const response = await axios.post(`${BASE_URL}register`, userData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      const registeredUser = response.data;
      console.log("Registered User:", registeredUser);
      return registeredUser;
    } else {
      throw new Error("Failed to register user");
    }
  } catch (error) {
    console.error("Error registering user:", error.message);
    throw new Error("Failed to register user");
  }
};
// login
export const login = async (userData) => {
  try {
    
    let accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      accessToken = await fetchToken();
    }

    const response = await axios.post(`${BASE_URL}login`, userData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      const responseData = response.data;
      return responseData;
    } else {
      throw new Error("Failed to login");
    }
  } catch (error) {
    throw new Error("Failed to login");
  }
}; 

// Function to register the form data to API endpoint /createslipleaguelead
export const registerLeagueLeadSlip = async (accessToken, formData) => {
  try {
    const response = await axios.post(`${BASE_URL}createslipleaguelead`, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      const registeredSlip = response.data;
      console.log("Registered League Lead Slip:", registeredSlip);
      return registeredSlip;
    } else {
      throw new Error("Failed to register league lead slip");
    }
  } catch (error) {
    console.error("Error registering league lead slip:", error.message);
    throw new Error("Failed to register league lead slip");
  }
};
// function for regestration of one to one  form of slips 
export const createRecordCBR = async (accessToken, recordData) => {
  try {
    const response = await axios.post(`${BASE_URL}createrecordcbr`, recordData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      const createdRecord = response.data;
      console.log("Created Record CBR:", createdRecord);
      return createdRecord;
    } else {
      throw new Error("Failed to create record CBR");
    }
  } catch (error) {
    console.error("Error creating record CBR:", error.message);
    throw new Error("Failed to create record CBR");
  }
};
// Function for regestration of  cbr form of slips
export const registerRecordCBR = async (accessToken, recordData) => {
  try {
    const response = await axios.post(`${BASE_URL}createrecordcbr`, recordData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      const createdRecord = response.data;
      console.log("Created Record CBR:", createdRecord);
      return createdRecord;
    } else {
      throw new Error("Failed to create record CBR",Error);
    }
  } catch (error) {
    console.error("Error creating record CBR:", error.message);
    throw new Error("Failed to create record CBR");
  }
};

// function to send data and request to admon to get approval for event 

// Function for registration of CBR form of slips
export const registerEvent = async (accessToken, eventData) => {
  try {
    const response = await axios.post(`${BASE_URL}createevent`, eventData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      const createdEvent = response.data;
      console.log("Created Event CBR:", createdEvent);
      return createdEvent;
    } else {
      throw new Error("Failed to create event CBR");
    }
  } catch (error) {
    console.error("Error creating event CBR:", error.message);
    throw new Error("Failed to create event CBR");
  }
};
// get api ""fetching or apperance of events of 
// Function to fetch data from API endpoint /getevent
export const fetchEvents = async (accessToken) => {
  try {
    const response = await axios.get(`${BASE_URL}getevents`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      const eventData = response.data;
      console.log("Events data:", eventData); // Log the data for debugging
      console.log(eventData)
      return eventData;
    } else {
      throw new Error("Failed to fetch events");
    }
  } catch (error) {
    console.error("Error fetching events:", error.message);
    throw new Error("Failed to fetch events");
  }
};
