import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Link, useNavigate } from "react-router-dom";
import { fetchToken, registerEvent } from "../../../utils/LeagueUtils";

const Form = () => {
  const [usereventData, setEventData] = useState({
    id:"",
    companyid:"",
    name: "",
    type: "",
    message: "",
    date: new Date(),
    isAllDayEvent: false,
  });
  //

  const fetchData = async () => {
    try {
      const accessToken = await fetchToken(username, password);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  //
  const handleInputChange = async (event) => {
    const {name, value, type, checked } = event.target;
    setEventData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const { companyid,id,name, type, message, date, isAllDayEvent } = usereventData;
  const navigate = useNavigate();
  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    try {
      const accessToken = await fetchToken(username, password);

      const eventData = {
      //  
        EventName: usereventData.name,
        EmailAddress: usereventData.type,
        Description: usereventData.message,
        EventDate: usereventData.date,
        FromTime: "2.20",
        ToTime: "3.45",
        IsAllDayEvent: 0,
        Image:null,
        UserId: usereventData.id,
        CompanyId: usereventData.companyid,
        CreatedBy: "doremonBoss",
      };

      const cbrFormResponse = await registerEvent(accessToken, eventData);
      console.log("Registerd succesfully :", cbrFormResponse,eventData);
    } catch (error) {
      console.error("Error registering league:", error.message);
      if (error.response && error.response.status === 200) {
        console.log("API Message:", error.response.data.message);
      }
    }
    // Do something with formData, such as sending it to the server for approval

    console.log(usereventData);
    // After processing the data, navigate to the "/pendingapproval" route

    navigate("/pendingapproval");
  };
  const username = "dtechraabit";
  const password = "admin123";

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="lg:w-[50%] lg:mx-auto mb-4">
        <form onSubmit={handleFormSubmit} className="lg:pt-[4rem]">
        <input type="text"  value={id} name="id" onChange={handleInputChange} placeholder="userId"   id="" />
        <input type="text"  value={companyid}   onChange={handleInputChange} name="companyid" placeholder="companyId"   id="" />
        
        
        
          <div className="mb-4 ">
            <label
              className="block text-[#005125] font-bold mb-2 mont-serif"
              htmlFor="name"
            >
              Event Name:
            </label>
            <input
              className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="name"
              type="text"
              name="name"
              value={name}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4 lg:mt-[2rem]">
            <label
              className="block text-[#005125] font-bold mb-2 mont-serif"
              htmlFor="email"
            >
              Event Type:
            </label>
            <input
              className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="email"
              type="text"
              name="type"
              value={type}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4 lg:mt-[4rem]">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="message"
            >
              Message:
            </label>
            <textarea
              className="mont-serif appearance-none border border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none rounded-md"
              id="message"
              rows="8"
              name="message"
              value={message}
              onChange={handleInputChange}
            />
          </div>
          <div className="calender mx-auto">
            <Calendar
              className="mb-4 mx-auto w-full rounded-md mt-8"
              onChange={(date) =>
                setEventData((prevFormData) => ({ ...prevFormData, date }))
              }
              value={date}
            />
          </div>
          <div className="flex flex-row items-center gap-2 justify-end mb-4 mt-6">
            <span className="ml-3 font-medium text-[#005125] mont-serif">
              All Day Event
            </span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                name="isAllDayEvent"
                checked={isAllDayEvent}
                onChange={handleInputChange}
              />
              <div
                className={`w-11 h-6 bg-green-300 peer-focus:outline-none  ${
                  isAllDayEvent ? "peer-checked:bg-green-700" : ""
                } dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-green-200 ${
                  isAllDayEvent ? "peer-checked:after:translate-x-full" : ""
                } peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600`}
              ></div>
            </label>
          </div>
          <hr className="border-b border-[#007033] w-full mb-8" />
          {/* Rest of the form... */}
          <div className="flex flex-col justify-center items-center gap-2 mt-[4rem] mb-4">
            <button
              type="submit"
              // onClick={handleCreateEvent}
              className="lg:w-[11rem] btn-ad mont-serif text-black"
            >
              Create Event
            </button>
            <button className="lg:w-[8rem] btn-dc border border-green-700 mont-serif text-black">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;
