import React, { useState, useEffect } from "react";
import { fetchToken, createRecordCBR } from "../../../utils/LeagueUtils";
function Onetoone() {
  const [onetooneForm, setoneForm] = useState({
    MeetingWith: "",
    Invitedby: "",
    MeetingLocation: "",
    DateandTime: "",
    DicussionTopic: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setoneForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchData = async () => {
    try {
      const accessToken = await fetchToken(username, password);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const accessToken = await fetchToken(username, password);

      const onetooneFormData = {
        // passing league as static will change after updating the UI
        ListType: "League",
        MeetingWith: onetooneForm.MeetingWith,
        InvitedBy: onetooneForm.Invitedby,
        MeetingLocation: onetooneForm.MeetingLocation,
        MeetingDate: onetooneForm.DateandTime,
        MeetingTime: "",
        DiscussionTopic: onetooneForm.DicussionTopic,
        CompanyId: "34",
        UserId: "24",
      };
      const registerOnetoOne = await createRecordCBR(
        accessToken,
        onetooneFormData
      );
      console.log("Registered one to one :", registerOnetoOne);
    } catch (error) {
      console.error("Error registering one to one form:", error.message);
      if (error.response && error.response.status === 200) {
        console.log("API Message:", error.response.data.message);
      }
    }

    // printing the form data in console
    console.log(onetooneForm);
  };
  // API validation
  const username = "dtechraabit";
  const password = "admin123";
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="xs:mt-6 w-[50%] mx-auto lg:mt-[3rem]"
      >
        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field1"
          >
            Meeting with
          </label>
          <input
            className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="MeetingWith"
            value={onetooneForm.MeetingWith}
            onChange={handleChange}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field2"
          >
            Invited by:
          </label>
          <input
            className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="Invitedby"
            value={onetooneForm.Invitedby}
            onChange={handleChange}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field3"
          >
            Meeting Location:
          </label>
          <input
            className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="MeetingLocation"
            value={onetooneForm.MeetingLocation}
            onChange={handleChange}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field4"
          >
            Date and Time :
          </label>
          <input
            className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="date"
            name="DateandTime"
            value={onetooneForm.DateandTime}
            onChange={handleChange}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field5"
          >
            Discussion Topic:
          </label>
          <input
            className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="DicussionTopic"
            value={onetooneForm.DicussionTopic}
            onChange={handleChange}
          />
        </div>

        <div className="lg:mb-[4rem] flex flex-col gap-3 justify-center items-center mt-[3rem]">
          <button
            className="btn-league w-[17rem] p-[4px] rounded-md mont-serif fade-in-left"
            type="submit"
          >
            Confirm
          </button>
        </div>
      </form>
    </>
  );
}

export default Onetoone;
