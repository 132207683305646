import React, { useState,useEffect } from "react";
import { fetchToken, registerRecordCBR } from "../../../utils/LeagueUtils";

function Recordcbr() {
  const [cbrForm, setCBRFORM] = useState({
    ThankyouTo: "",
    BussinessType: "",
    MeetingLocation: "",
    Comments: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCBRFORM((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [newBussinessSelected, setNewBussinessSelected] = useState(true);
  const [repeatBussinessSelected, setRepeatBussinessSelected] = useState(false);

  const handleNewButtonClick = () => {
    setCBRFORM((prevForm) => ({
      ...prevForm,
      BussinessType: "New", // Update the BussinessType property to "New"
    }));
    setNewBussinessSelected(true);
    setRepeatBussinessSelected(false);
  };

  const handleRepeatButtonClick = () => {
    setCBRFORM((prevForm) => ({
      ...prevForm,
      BussinessType: "Repeat", // Update the BussinessType property to "Repeat"
    }));
    setNewBussinessSelected(false);
    setRepeatBussinessSelected(true);
  };
  //
  const [insideSelected, setinsideSelected] = useState(true);
  const [outsideSelected, setoutsideSelected] = useState(false);
  const [tier3Selected, settierSelected] = useState(false);

  const handleButton1Click = () => {
    setCBRFORM((prevForm) => ({
      ...prevForm,
      MeetingLocation: "inside", // Update the BussinessType property to "New"
    }));

    setinsideSelected(true);
    setoutsideSelected(false);
    settierSelected(false);
  };

  const handleButton2Click = () => {
    setCBRFORM((prevForm) => ({
      ...prevForm,
      MeetingLocation: "outside", // Update the BussinessType property to "New"
    }));
    setinsideSelected(false);
    setoutsideSelected(true);
    settierSelected(false);
  };

  const handleButton3Click = () => {
    setCBRFORM((prevForm) => ({
      ...prevForm,
      MeetingLocation: "tier 3+", // Update the BussinessType propertytier to "New"
    }));

    setinsideSelected(false);
    setoutsideSelected(false);
    settierSelected(true);
  };
// token

  const fetchData = async () => {
    try {
      const accessToken = await fetchToken(username, password);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
// handling the api response from here 
  const handleSubmit = async (event) => {
    event.preventDefault();
  
try {
  const accessToken = await fetchToken(username, password);
  const cbrFormData ={
    // passing league as static will change after updating the UI
    ListType: "League",
    ThankyouTO:cbrForm.ThankyouTo,
    BussinessType:cbrForm.BussinessType,
    LeagueLeadType:"doremon league",
    Comments:cbrForm.Comments,
    CompanyId:"24",
    UserId:"34"
  }

  const recordcbrresponse = await registerRecordCBR(
    accessToken,
    cbrFormData
  );

  console.log("Registered recordes cbr :", recordcbrresponse);

} catch (error) {
  console.error("Error registering league:", error.message);
  if (error.response && error.response.status === 200) {
    console.log("API Message:", error.response.data.message);
  }
}





  


    console.log(cbrForm);
  };
  //
  const username = "dtechraabit";
  const password = "admin123";

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="xs:mt-[6px] w-[50%] mx-auto lg:mt-[3rem]"
      >
        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field1"
          >
            Thankyou to :
          </label>
          <input
            className="mont-serif  appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="ThankyouTo"
            value={cbrForm.ThankyouTo}
            onChange={handleChange}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field2"
          >
            Bussiness Type :
          </label>

          <div className="flex flex-row justify-center items-center mx-auto gap-2">
            <input
              type="button"
              className={`border ${
                newBussinessSelected
                  ? "bg-green-700 text-white"
                  : "bg-white border border-green-700 text-black"
              } border-green-900 w-[19rem] rounded-md p-[9px] mont-serif fade-in-right`}
              onClick={handleNewButtonClick}
              value="New"
            />

            <input
              type="button"
              className={`border ${
                repeatBussinessSelected
                  ? "bg-green-700 text-white rounded-md"
                  : "bg-white border border-green-700 text-black"
              } bg-green-600 text-black w-[19rem] p-[9px] rounded-md mont-serif fade-in-right`}
              onClick={handleRepeatButtonClick}
              value="repeat"
            />
          </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field3"
          >
            Meeting Location:
          </label>
          <div className="flex flex-row justify-center items-center mx-auto gap-[3px]">
            <input
              type="button"
              className={`border ${
                insideSelected
                  ? "bg-green-700 rounded-md text-white"
                  : "bg-white"
              } border-green-900 rounded-md text-black w-[19rem] p-[9px] mont-serif fade-in-right`}
              onClick={handleButton1Click}
              value="inside"
            />

            <input
              type="button"
              value="outside"
              className={`border ${
                outsideSelected
                  ? "bg-green-700 rounded-md text-white"
                  : "bg-white"
              } bg-green-600  border border-green-700 rounded-md text-black w-[19rem] p-[9px] mont-serif fade-in-right`}
              onClick={handleButton2Click}
            />

            <input
              type="button"
              className={`border ${
                tier3Selected
                  ? " bg-green-700 rounded-md text-white"
                  : "text-[#005125] bg-white"
              } border-green-900 w-[19rem] p-[9px] mont-serif fade-in-right rounded-md`}
              onClick={handleButton3Click}
              value="tier 3+"
            />
          </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field4"
          >
            Comments
          </label>
          <input
            className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="Comments"
            value={cbrForm.Comments}
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-col gap-3 justify-center items-center mt-[3rem] lg:mb-9 xxl:mb-9">
          <button
            className="btn-league w-[17rem] p-[4px] rounded-md mont-serif fade-in-left xxl:mb-8"
            type="submit"
          >
            Confirm
          </button>
        </div>
      </form>
    </>
  );
}

export default Recordcbr;
