import React from 'react'
import { BsFillPencilFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { Link } from "react-router-dom";
import AdminSidebar from "../../AdminSidebar"
import AdminSearchbar from "../../AdminSearchbar"
import AdminFooter from "../../Adminfooter";
import Adminfooter from '../../Adminfooter';

export default function PendingEvents() {
  return (
    <>

<div className="lg:block xs:hidden">
        <AdminSidebar />
      </div>

      <div className="hidden lg:block">
          <header className=" header bg-white shadow py-4 px-4 ">
            <AdminSearchbar />
          </header>
        </div>
          <div   className="flex fex-row  xs:gap-[7rem] xs:relative xs:top-8 justify-center lg:gap-[37rem] xxl:gap-[47rem]  relative lg:top-[33px]">
    <h1  style={{whiteSpace:"nowrap"}}   className="font-bold inline text-2xl text-[#005125] mont-serif relative left-[3rem]">Request Pending Event</h1>
    <button   className="p-1 justify-center lg:gap-[7px] lg:w-[11rem] flex items-center rounded-md border border-green-700 mont-serif"> <span   className="text-[#005125]"><FiFilter/></span> <span> Filter By </span> </button>
          </div>
          <div   className="lg:container lg:mx-auto">
            <div   className="flex flex-col text-center w-full mb-20"></div>
            <div   className=" lg:w-[90%] mx-auto overflow-auto">
              <table   className="table-auto w-full text-left whitespace-no-wrap">
                <thead>
                  <tr>
                    <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125] rounded-tl rounded-bl"></th>
                    <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125] rounded-tl rounded-bl"></th>
                    <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125] rounded-tl rounded-bl">
                      Member Name
                    </th>
                    <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125]">
                      Segment
                    </th>
                    <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125]">
                      
                    </th>
                   
                 
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td   className="w-10 text-center">
                      <input name="plan" type="radio" />
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                    <div   className="image-grid lg:w-[50px]">
                    </div>
                    </td>
    
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      user name
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      Accomodation
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      26 Feb, 2023
                    </td>
                
                  </tr>
                  <tr>
                    <td   className="border-t-2 border-gray-200 w-10 text-center">
                      <input name="plan" type="radio" />
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                    <div   className="image-grid lg:w-[50px]">
                    </div>
                    </td>
    
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      user name
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      Accomodation
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      26 Feb, 2023
                    </td>
                
                  </tr>
                  <tr>
                    <td   className="border-t-2 border-gray-200 w-10 text-center">
                      <input name="plan" type="radio" />
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                    <div   className="image-grid lg:w-[50px]">
                    </div>
                    </td>
    
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      user name
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      Accomodation
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      26 Feb, 2023
                    </td>
                
                  </tr>
                  <tr>
                    <td   className="border-t-2 border-b-2 border-gray-200 w-10 text-center">
                      <input name="plan" type="radio" />
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                    <div   className="image-grid lg:w-[50px]">
                    </div>
                    </td>
    
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      user name
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      Accomodation
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      26 Feb, 2023
                    </td>
                
                  </tr>
                  <tr>
                    <td   className="border-t-2 border-b-2 border-gray-200 w-10 text-center">
                      <input name="plan" type="radio" />
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                    <div   className="image-grid lg:w-[50px]">
                    </div>
                    </td>
    
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      user name
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      Accomodation
                    </td>
                    <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                      26 Feb, 2023
                    </td>
                
                  </tr>
                </tbody>
              </table>
    <div   className="button mx-auto flex mt-16 xxl:mb-16 lg:mb-16  justify-center">
    <Link to="/admin-Event" >
    <button     className="lg:w-[10rem] btn-dc border border-green-700 mont-serif text-black mx-auto ">Go Back</button>
    </Link>
    </div>
                  
            </div>
          </div>
<div className="xs:hidden block">
          <Adminfooter/>
          </div>
        </>
  )
}
